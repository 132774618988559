var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "pagination-block",
    style: `--alignment:${_vm.alignment}`
  }, [_c("el-pagination", {
    attrs: {
      background: _vm.background,
      disabled: _vm.disabled,
      "current-page": _vm.currentPage,
      "page-size": _vm.pageSize,
      "page-sizes": _vm.pageSizes,
      layout: _vm.layout,
      total: _vm.total,
      "pager-count": _vm.pagerCount
    },
    on: {
      "current-change": _vm.handleCurrentChange,
      "size-change": _vm.handleSizeChange
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };