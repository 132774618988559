import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.filter.js";
import "core-js/modules/es.iterator.some.js";
import { get, updateItem, update } from '@/api/common';
import store from '@/store';
import mixins from '@/utils/mixins';
import pagination from '@/components/Pagination/index.vue';
const lastNotifIdKey = 'messageLastNotif';
export default {
  components: {
    pagination
  },
  mixins: [mixins.list],
  data() {
    return {
      showMessageIcon: false,
      unreadMessages: '',
      drawer: false,
      activeName: 'unread',
      tableHeight: 0,
      endpoint: '/messages/',
      lastNotifId: null
    };
  },
  created() {
    this.lastNotifId = window.localStorage.getItem(lastNotifIdKey);
    this.handleMessage(true);
  },
  methods: {
    async handleMessage(isCreated) {
      if (store && store.getters.permissions.length > 0) {
        if (store.getters.permissions.some(e => e.code === 'VIEW_MESSAGE')) {
          this.showMessageIcon = true;
          const {
            code,
            data
          } = await get(`${this.endpoint}unread_message_check`);
          if (code === 0) {
            const unread = data.unread_message_count || 0;
            if (unread === 0) {
              this.unreadMessages = '';
            } else if (unread < 100) {
              this.unreadMessages = `${unread}`;
            } else {
              this.unreadMessages = '99+';
            }
            if (isCreated && data.today_unread_messages.length > 0) {
              this.lastNotifId = data.today_unread_messages[data.today_unread_messages.length - 1].id;
              window.localStorage.setItem(lastNotifIdKey, this.lastNotifId);
            } else if (data.today_unread_messages.length > 0) {
              let notifList = [];
              if (this.lastNotifId == null) {
                notifList = data.today_unread_messages;
              } else {
                notifList = data.today_unread_messages.filter(e => e.id > this.lastNotifId);
              }
              if (notifList.length > 0) {
                this.lastNotifId = notifList[notifList.length - 1].id;
                window.localStorage.setItem(lastNotifIdKey, this.lastNotifId);
                this.showNotifications(notifList);
              }
            }
          }
          setTimeout(() => {
            this.handleMessage();
          }, 1000 * 60 * 1);
        }
      } else {
        setTimeout(() => {
          this.handleMessage();
        }, 1000);
      }
    },
    showNotifications(notifList) {
      if (notifList && notifList.length > 0) {
        const notif = notifList.shift();
        const notifInstance = this.$notify({
          title: notif.message_type_name,
          dangerouslyUseHTMLString: true,
          duration: 0,
          message: `<div>
                      <div class="message-content ellipsis">${notif.content}</div>
                      <div class="divider"></div>
                      <div class="bottom" style="margin-bottom:0;">
                        <div class="create-at">
                        ${notif.create_at}
                        </div>
                        <div
                          class="set-unread"
                        >
                          <a>查看详情</a>
                        </div>
                      </div>
                    </div>`,
          customClass: 'message',
          onClick: () => {
            notifInstance.close();
            this.handleSetUnread(notif);
          }
        });
        setTimeout(() => {
          this.showNotifications(notifList);
        }, 200);
      }
    },
    async showMessages() {
      setTimeout(() => {
        this.computeTableHeight();
        setTimeout(() => {
          this.computeTableHeight();
        }, 100);
      }, 100);
      this.activeName = 'unread';
      this.dataList = [];
      this.query.page = 1;
      this.loadList();
    },
    async loadList() {
      this.listloading = true;
      const list = document.getElementById('messageList');
      if (list) {
        list.scrollTo(0, 0);
      }
      const {
        code,
        data
      } = await get(this.endpoint, {
        status: this.activeName === 'unread' ? 0 : null,
        ...this.query
      });
      if (code === 0) {
        this.dataList = data.items;
        this.meta.total = data.total;
        this.meta.current_page = data.page;
      }
      this.listloading = false;
    },
    handleClick() {
      this.query.page = 1;
      this.loadList();
    },
    async handleSetUnread(row) {
      this.$alert(`<div>
          <div class="message-content full-content">${row.content}</div>
          <div class="divider"></div>
          <div class="create-at">${row.create_at}</div>
        </div>`, row.message_type_name, {
        dangerouslyUseHTMLString: true,
        confirmButtonText: '关闭',
        customClass: 'message message-dialog'
      });
      await updateItem(this.endpoint, row.id);
      this.loadList();
      this.handleMessage();
    },
    async handleClearAll() {
      await update(`${this.endpoint}clear`);
      this.loadList();
      this.handleMessage();
    }
  }
};