export default {
  name: 'Pagination',
  props: {
    total: {
      type: Number,
      default: 0
    },
    currentPage: {
      type: Number,
      default: 1
    },
    pageSize: {
      type: Number,
      default: 10
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 50, 100]
    },
    disabled: {
      type: Boolean,
      default: false
    },
    layout: {
      type: String,
      default: 'sizes, total, prev, pager, next, jumper'
    },
    background: {
      type: Boolean,
      default: true
    },
    pagerCount: {
      type: Number,
      default: 7
    },
    alignment: {
      type: String,
      default: 'center;'
    }
  },
  data() {
    return {};
  },
  methods: {
    handleCurrentChange(val) {
      this.$emit('go', val);
    },
    handleSizeChange(val) {
      this.$emit('changeSize', val);
    }
  }
};