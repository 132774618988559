var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_vm.showMessageIcon ? _c("a", {
    staticClass: "notif",
    on: {
      click: function ($event) {
        _vm.drawer = true;
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-bell"
  }), _vm.unreadMessages.length > 0 ? _c("div", {
    staticClass: "unread"
  }, [_vm._v(" " + _vm._s(_vm.unreadMessages) + " ")]) : _vm._e()]) : _vm._e(), _c("el-drawer", {
    attrs: {
      visible: _vm.drawer,
      size: 380
    },
    on: {
      "update:visible": function ($event) {
        _vm.drawer = $event;
      },
      open: _vm.showMessages
    }
  }, [_c("div", {
    staticClass: "container"
  }, [_c("el-tabs", {
    on: {
      "tab-click": _vm.handleClick
    },
    model: {
      value: _vm.activeName,
      callback: function ($$v) {
        _vm.activeName = $$v;
      },
      expression: "activeName"
    }
  }, [_c("el-tab-pane", {
    attrs: {
      label: _vm.$t("message.unread"),
      name: "unread"
    }
  }), _c("el-tab-pane", {
    attrs: {
      label: _vm.$t("message.all"),
      name: "all"
    }
  })], 1), _vm.dataList.length > 0 ? _c("div", {
    staticClass: "clear-all",
    on: {
      click: _vm.handleClearAll
    }
  }, [_c("a", [_vm._v(_vm._s(_vm.$t("message.clear_all")))])]) : _vm._e(), _c("div", {
    style: `height: ${_vm.tableHeight + 10}px;overflow: scroll;`,
    attrs: {
      id: "messageList"
    }
  }, [_vm.dataList.length == 0 && !_vm.listloading ? _c("div", {
    staticClass: "empty"
  }, [_c("el-image", {
    staticStyle: {
      width: "100px",
      height: "100px"
    },
    attrs: {
      src: require("@/assets/notif.png"),
      fit: "contain"
    }
  }), _c("div", [_vm._v(" " + _vm._s(_vm.$t("message.empty")) + " ")])], 1) : _c("div", _vm._l(_vm.dataList, function (item) {
    return _c("div", {
      key: item.id,
      staticClass: "message"
    }, [_c("div", {
      staticClass: "message-type"
    }, [_vm._v(" [" + _vm._s(item.message_type_name) + "] ")]), _c("div", {
      staticClass: "message-content"
    }, [_vm._v(" " + _vm._s(item.content) + " ")]), _c("div", {
      staticClass: "divider"
    }), _c("div", {
      staticClass: "bottom"
    }, [_c("div", {
      staticClass: "create-at"
    }, [_vm._v(" " + _vm._s(item.create_at) + " ")]), item.status == 0 ? _c("div", {
      staticClass: "set-unread",
      on: {
        click: () => _vm.handleSetUnread(item)
      }
    }, [_c("a", [_vm._v(_vm._s(_vm.$t("message.set_unread")))])]) : _vm._e()])]);
  }), 0)]), _c("pagination", {
    style: `${_vm.dataList.length === 0 ? "opacity: 0" : ""}`,
    attrs: {
      total: _vm.meta.total,
      "page-size": _vm.meta.page_size,
      "current-page": _vm.meta.current_page,
      disabled: _vm.listloading,
      layout: "total, prev, pager, next",
      background: false,
      "pager-count": 5,
      alignment: "left"
    },
    on: {
      go: _vm.handlePageTurn,
      changeSize: _vm.handlePageSize
    }
  })], 1)])], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };