var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("el-dialog", {
    staticClass: "edit-dialog",
    attrs: {
      title: _vm.title,
      "close-on-click-modal": false,
      visible: _vm.dialogVisible,
      width: `${_vm.width}px`,
      "validate-on-rule-change": false
    },
    on: {
      "update:visible": function ($event) {
        _vm.dialogVisible = $event;
      },
      close: _vm.handleClose,
      open: _vm.handleOpen
    }
  }, [_vm._t("header"), _c("el-form", {
    ref: "form",
    attrs: {
      model: _vm.form,
      rules: _vm.rules,
      "label-position": _vm.labelPosition,
      inline: true
    }
  }, [_vm._t("pre-form", null, {
    form: _vm.form,
    loading: _vm.loading
  }), _vm.formItems != null ? _c("div", _vm._l(_vm.formItems, function (item) {
    return _c("el-form-item", {
      key: item.key,
      class: item.class,
      style: item.style == null ? "" : typeof item.style == "string" ? item.style : item.style(_vm.form),
      attrs: {
        "label-width": item.labelWidth || "100px",
        label: item.label || item.key,
        prop: item.key,
        required: typeof item.required === "function" ? item.required(_vm.form) : item.required,
        "show-message": item.showMessage || false
      }
    }, [item.options != null ? _c("el-select", {
      attrs: {
        clearable: "",
        placeholder: _vm.$t(item.label),
        loading: _vm.loading,
        disabled: _vm.disableItem(item, _vm.form),
        multiple: item.multiple,
        "collapse-tags": true,
        filterable: item.filterable
      },
      on: {
        change: function ($event) {
          item.onChange && item.onChange(_vm.form);
        }
      },
      model: {
        value: _vm.form[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.form, item.key, $$v);
        },
        expression: "form[item.key]"
      }
    }, _vm._l(item.options(_vm.form), function (op) {
      return _c("el-option", {
        key: op.value,
        attrs: {
          label: op.label,
          value: op.value
        }
      });
    }), 1) : item.inputType == "integer" ? _c("el-input-number", {
      attrs: {
        disabled: _vm.disableItem(item, _vm.form),
        max: item.max,
        min: item.min
      },
      model: {
        value: _vm.form[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.form, item.key, $$v);
        },
        expression: "form[item.key]"
      }
    }) : item.inputType == "date" || item.inputType == "datetime" ? _c("el-date-picker", {
      attrs: {
        type: item.inputType,
        placeholder: item.label,
        "value-format": item.valueFormat,
        "picker-options": item.pickerOptions || {},
        clearable: item.clearable,
        disabled: _vm.disableItem(item, _vm.form)
      },
      model: {
        value: _vm.form[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.form, item.key, $$v);
        },
        expression: "form[item.key]"
      }
    }) : item.formItemPlaceholder === true ? _c("div") : item.inputType == "region" ? _c("el-cascader", {
      attrs: {
        size: "large",
        options: _vm.options,
        clearable: "",
        disabled: _vm.disableItem(item, _vm.form)
      },
      on: {
        change: function ($event) {
          return item.handleChange($event, _vm.form);
        }
      },
      model: {
        value: _vm.form[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.form, item.key, $$v);
        },
        expression: "form[item.key]"
      }
    }) : item.inputType == "cascader" ? _c("el-cascader", {
      attrs: {
        props: item.props,
        disabled: _vm.disableItem(item, _vm.form)
      },
      model: {
        value: _vm.form[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.form, item.key, $$v);
        },
        expression: "form[item.key]"
      }
    }) : item.inputType == "radio" ? _c("el-radio-group", {
      attrs: {
        disabled: _vm.disableItem(item, _vm.form)
      },
      model: {
        value: _vm.form[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.form, item.key, $$v);
        },
        expression: "form[item.key]"
      }
    }, _vm._l(item.groupItems, function (it) {
      return _c("el-radio", {
        key: it.name,
        attrs: {
          label: it.value
        }
      }, [_vm._v(" " + _vm._s(it.name) + " ")]);
    }), 1) : _c("el-input", {
      attrs: {
        placeholder: item.placeholder || _vm.$t("common.input_placeholder") + (item.label || item.key),
        clearable: "",
        disabled: _vm.disableItem(item, _vm.form),
        type: item.inputType || "text",
        rows: item.rows,
        maxlength: item.maxlength,
        "show-word-limit": item.showWordLimit
      },
      model: {
        value: _vm.form[item.key],
        callback: function ($$v) {
          _vm.$set(_vm.form, item.key, $$v);
        },
        expression: "form[item.key]"
      }
    })], 1);
  }), 1) : _vm._e(), _vm._t("default", null, {
    form: _vm.form,
    loading: _vm.loading,
    object: _vm.object
  })], 2), _vm.customFooter ? _vm._t("footer", null, {
    form: _vm.form,
    loading: _vm.loading,
    handleClose: _vm.handleClose,
    handleConfirm: _vm.handleConfirm
  }) : _c("span", {
    staticClass: "dialog-footer",
    attrs: {
      slot: "footer"
    },
    slot: "footer"
  }, [_vm.showCancel ? _c("el-button", {
    attrs: {
      disabled: _vm.loading
    },
    on: {
      click: _vm.handleClose
    }
  }, [_vm._v(_vm._s(_vm.$t("common.cancel")))]) : _vm._e(), _c("el-button", {
    attrs: {
      type: "primary",
      disabled: _vm.loading || !_vm.canConfirm(_vm.form)
    },
    on: {
      click: _vm.handleConfirm
    }
  }, [_vm._v(_vm._s(_vm.confirmText || _vm.$t("common.confirm")))])], 1)], 2);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };