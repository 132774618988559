import "core-js/modules/es.array.push.js";
import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.for-each.js";
import Vue from 'vue';
import Router from 'vue-router';
import Layout from '@/layout/index.vue';
Vue.use(Router);
const routes = [{
  path: '/',
  component: Layout,
  children: [{
    path: '/',
    component: () => import('@/views/dashboard/index.vue'),
    meta: {
      title: 'routes.dashboard',
      icon: 'data',
      permissions: ['VIEW_OVERVIEW']
    }
  }]
}, {
  path: '/influencer',
  component: Layout,
  redirect: '/influencer',
  meta: {
    title: 'routes.influencer.name',
    icon: 'people',
    permissions: ['VIEW_DAREN']
  },
  children: [{
    path: '',
    component: () => import('@/views/influencer/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }, {
    path: 'add',
    component: () => import('@/views/influencer/add.vue'),
    meta: {
      title: 'routes.influencer.add',
      noCache: true,
      activeMenu: '/influencer/add'
    },
    hidden: true
  }, {
    path: 'detail',
    component: () => import('@/views/influencer/detail.vue'),
    meta: {
      title: 'routes.influencer.detail',
      noCache: true,
      activeMenu: '/influencer'
    },
    hidden: true
  }]
}, {
  path: '/influencer/add',
  component: Layout,
  redirect: '/influencer/add',
  meta: {
    title: 'routes.influencer.add',
    permissions: ['VIEW_DAREN'],
    permissionBasedStyleClass: permissions => permissions.findIndex(e => e.code === 'VIEW_DAREN_CATEGORY') >= 0 ? 'pseudo-sub-menu-ext' : 'pseudo-sub-menu'
  },
  children: [{
    path: '',
    component: () => import('@/views/influencer/add.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}, {
  path: '/influencer/category',
  component: Layout,
  redirect: '/influencer/category',
  meta: {
    title: 'routes.influencer.category',
    permissions: ['VIEW_DAREN_CATEGORY'],
    permissionBasedStyleClass: permissions => permissions.findIndex(e => e.code === 'VIEW_DAREN_BLACK_LIST') >= 0 ? 'pseudo-sub-menu-ext' : 'pseudo-sub-menu'
  },
  children: [{
    path: '',
    component: () => import('@/views/influencer/category.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}, {
  path: '/influencer/blacklist',
  component: Layout,
  redirect: '/influencer/blacklist',
  meta: {
    title: 'routes.influencer.blacklist',
    permissions: ['VIEW_DAREN_BLACK_LIST'],
    styleClass: 'pseudo-sub-menu'
  },
  children: [{
    path: '',
    component: () => import('@/views/influencer/blacklist.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}, {
  path: '/qc',
  component: Layout,
  redirect: '/qc/data',
  meta: {
    title: '投放管理',
    icon: 'invest',
    permissions: ['VIEW_QC_MANAGE']
  },
  children: [{
    path: '/qc/data',
    component: () => import('@/views/qc/data.vue'),
    meta: {
      title: '达人数据'
    }
  }, {
    path: '/qc/advertise',
    component: () => import('@/views/qc/advertiser.vue'),
    meta: {
      title: '广告账户'
    }
  }, {
    path: '/qc/authorized',
    component: () => import('@/views/qc/authorized.vue'),
    meta: {
      title: '授权账号'
    }
  }]
}, {
  path: '/following',
  component: Layout,
  redirect: '/following',
  meta: {
    title: 'routes.following.name',
    icon: 'like',
    permissions: ['VIEW_UDM']
  },
  children: [{
    path: '',
    component: () => import('@/views/following/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }, {
    path: 'detail',
    component: () => import('@/views/following/detail.vue'),
    meta: {
      title: 'routes.following.detail',
      noCache: true,
      activeMenu: '/following'
    },
    hidden: true
  }]
}, {
  path: '/todo',
  component: Layout,
  redirect: '/todo',
  meta: {
    title: 'routes.todo',
    icon: 'todo',
    permissions: ['VIEW_TODO']
  },
  children: [{
    path: '',
    component: () => import('@/views/todo/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}, {
  path: '/product',
  component: Layout,
  redirect: '/product',
  meta: {
    title: 'routes.product',
    icon: 'product',
    permissions: ['VIEW_PRODUCT']
  },
  children: [{
    path: '',
    component: () => import('@/views/product/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }, {
    path: 'dish',
    component: () => import('@/views/product/dish.vue'),
    meta: {
      title: 'product.related_dish_count_p',
      noCache: true,
      activeMenu: '/product'
    },
    hidden: true
  }]
},
// {
//   path: '/dish',
//   component: Layout,
//   redirect: '/dish',
//   meta: {
//     title: 'routes.dish',
//     icon: 'dish',
// permissions: [process.env.VUE_APP_HIDE_DISH_MENU === 'true'
// ? 'HIDE_VIEW_DISH' : 'VIEW_DISH'],
//   },
//   children: [
//     {
//       path: '',
//       component: () => import('@/views/dish/index.vue'),
//       meta: {
//         title: '',
//       },
//       hidden: true,
//     },
//   ],
// },
{
  path: '/sample',
  component: Layout,
  redirect: '/sample',
  meta: {
    title: 'routes.sample',
    icon: 'sample',
    permissions: ['VIEW_SAMPLE_SEND']
  },
  children: [{
    path: '',
    component: () => import('@/views/sample/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}, {
  path: '/promotion',
  component: Layout,
  redirect: '/promotion',
  meta: {
    title: 'routes.promotion.name',
    icon: 'extension',
    permissions: ['VIEW_PROMOTION']
  },
  children: [{
    path: '',
    component: () => import('@/views/promotion/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }, {
    path: 'detail',
    component: () => import('@/views/promotion/detail.vue'),
    meta: {
      title: 'routes.promotion.detail',
      noCache: true,
      activeMenu: '/promotion'
    },
    hidden: true
  }]
}, {
  path: '/cooperation',
  component: Layout,
  redirect: '/cooperation',
  meta: {
    title: 'routes.cooperation.name',
    icon: 'cooperation',
    permissions: ['VIEW_COOPERATION']
  },
  children: [{
    path: '',
    component: () => import('@/views/cooperation/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }, {
    path: 'detail',
    component: () => import('@/views/cooperation/detail.vue'),
    meta: {
      title: 'routes.cooperation.detail',
      noCache: true,
      activeMenu: '/cooperation'
    },
    hidden: true
  }]
},
// {
//   path: '/target',
//   component: Layout,
//   redirect: '/target',
//   meta: {
//     title: 'routes.target',
//     icon: 'target',
//     permissions: ['VIEW_TARGET'],
//   },
//   children: [
//     {
//       path: '',
//       component: () => import('@/views/target/index.vue'),
//       meta: {
//         title: '',
//       },
//       hidden: true,
//     },
//   ],
// },
{
  path: '/trade',
  component: Layout,
  redirect: '/trade/order',
  meta: {
    title: 'routes.trade.name',
    icon: 'trade',
    permissions: [process.env.VUE_APP_HIDE_TRADE_MENU === 'true' ? 'HIDE_VIEW_ORDER' : 'VIEW_ORDER']
  },
  children: [{
    path: 'order',
    component: () => import('@/views/trade/order.vue'),
    meta: {
      title: 'routes.trade.order'
    }
  }, {
    path: 'video',
    component: () => import('@/views/trade/video.vue'),
    meta: {
      title: 'routes.trade.video'
    }
  }, {
    path: 'live',
    component: () => import('@/views/trade/live.vue'),
    meta: {
      title: 'routes.trade.live'
    }
  }, {
    path: 'record',
    component: () => import('@/views/trade/record.vue'),
    meta: {
      title: 'routes.trade.record'
    }
  }]
}, {
  path: '/review',
  component: Layout,
  redirect: '/review/sample',
  meta: {
    title: 'routes.review.name',
    icon: 'review',
    permissions: ['VIEW_SAMPLE_SEND_REVIEWS', 'VIEW_PROMOTION_REVIEWS']
  },
  children: [{
    path: 'sample',
    component: () => import('@/views/review/index.vue'),
    meta: {
      title: 'routes.review.sample'
    }
  }, {
    path: 'promotion',
    component: () => import('@/views/review/index.vue'),
    meta: {
      title: 'routes.review.promotion'
    }
  }, {
    path: 'sample/detail',
    component: () => import('@/views/review/detail.vue'),
    meta: {
      title: 'routes.review.detail',
      noCache: true,
      activeMenu: '/review/sample'
    },
    hidden: true
  }, {
    path: 'promotion/detail',
    component: () => import('@/views/review/detail.vue'),
    meta: {
      title: 'routes.review.detail',
      noCache: true,
      activeMenu: '/review/promotion'
    },
    hidden: true
  }]
}, {
  path: '/tag',
  component: Layout,
  redirect: '/tag',
  meta: {
    title: 'routes.tag',
    icon: 'tag',
    permissions: ['VIEW_TAG']
  },
  children: [{
    path: '',
    component: () => import('@/views/tag/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }, {
    path: 'daren',
    component: () => import('@/views/tag/daren.vue'),
    meta: {
      title: 'tag.count',
      noCache: true,
      activeMenu: '/tag'
    },
    hidden: true
  }]
}, {
  path: '/unbind',
  component: Layout,
  redirect: '/unbind',
  meta: {
    title: 'routes.unbind',
    icon: 'unbind',
    permissions: ['VIEW_CANCELLED_COOPERATION']
  },
  children: [{
    path: '',
    component: () => import('@/views/unbind/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}, {
  path: '/account',
  component: Layout,
  redirect: '/account',
  meta: {
    title: 'routes.account',
    icon: 'account',
    permissions: ['CREATE_USER']
  },
  children: [{
    path: '',
    component: () => import('@/views/account/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }, {
    path: 'groups',
    component: () => import('@/views/account/groups.vue'),
    meta: {
      title: 'routes.group',
      noCache: true,
      activeMenu: '/account'
    },
    hidden: true
  }, {
    path: 'detail',
    component: () => import('@/views/account/detail.vue'),
    meta: {
      title: 'routes.account_detail',
      noCache: true,
      activeMenu: '/account'
    },
    hidden: true
  }]
}, {
  path: '/settings',
  component: Layout,
  redirect: '/settings',
  meta: {
    title: 'routes.settings',
    icon: 'power',
    permissions: ['VIEW_SETTING_USER']
  },
  children: [{
    path: '',
    component: () => import('@/views/settings/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}, {
  path: '/profile',
  component: Layout,
  redirect: '/profile',
  meta: {
    title: 'routes.profile',
    icon: 'setting'
  },
  children: [{
    path: '',
    component: () => import('@/views/profile/index.vue'),
    meta: {
      title: ''
    },
    hidden: true
  }]
}];

// 404 page must be placed at the end !!!
routes.push({
  path: '*',
  redirect: '/404',
  hidden: true
});
export const asyncRoutes = routes;

/**
 * constantRoutes
 * a base page that does not have permission requirements
 * all roles can be accessed
 */
export const constantRoutes = [{
  path: '/login',
  component: () => import('@/views/login/index.vue'),
  hidden: true
}, {
  path: '/404',
  component: () => import('@/views/404.vue'),
  hidden: true
}];
const createRouter = () => new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({
    y: 0
  }),
  routes: constantRoutes
});
const router = createRouter();

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter();
  router.matcher = newRouter.matcher; // reset router
}
export default router;
export function routeTo(e, path, query) {
  if (e.metaKey) {
    if (query && Object.keys(query).length > 0) {
      path = `${path}?`;
      Object.keys(query).forEach(k => {
        path = `${path}${k}=${query[k]}&`;
      });
      path = path.substring(0, path.length - 1);
    }
    window.open(`#${path}`, '_blank');
  } else {
    router.push({
      path,
      query
    });
  }
}