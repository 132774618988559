const getters = {
  sidebar: state => state.app.sidebar,
  device: state => state.app.device,
  unreadCount: state => state.app.unreadCount,
  name: state => state.user.name,
  username: state => state.user.username,
  en_name: state => state.user.en_name,
  permissions: state => state.user.permissions,
  role: state => state.user.role,
  permission_routes: state => state.permission.routes,
  timezone: state => state.settings.timezone,
  avatar: state => state.user.avatar
};
export default getters;