import "core-js/modules/es.iterator.constructor.js";
import "core-js/modules/es.iterator.for-each.js";
import { createItem, updateItem } from '@/api/common';
import { regionData } from 'element-china-area-data';
import { deepClone } from '@/utils';

/* eslint-disable func-names */
// eslint-disable-next-line no-extend-native
Function.prototype.clone = function () {
  const that = this;
  const temp = function temporary() {
    // eslint-disable-next-line prefer-rest-params
    return that.apply(this, arguments);
  };
  // eslint-disable-next-line no-restricted-syntax, no-unused-vars
  for (const k in this) {
    // eslint-disable-next-line no-prototype-builtins
    if (this.hasOwnProperty(k)) {
      temp[k] = this[k];
    }
  }
  return temp;
};
export default {
  name: 'EditDialog',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    object: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      required: true
    },
    width: {
      type: Number,
      default: 600
    },
    formFields: {
      type: Object,
      default: () => ({})
    },
    formRules: {
      type: Object,
      default: () => ({})
    },
    endpoint: {
      type: [String, Function],
      default: null
    },
    canConfirm: {
      type: Function,
      required: true
    },
    extraFormData: {
      type: Object,
      default: null
    },
    labelPosition: {
      type: String,
      default: 'top'
    },
    formatRequestData: {
      type: Function,
      default: null
    },
    formItems: {
      type: Array,
      default: null
    },
    createItemFunc: {
      type: Function,
      default: null
    },
    updateItemFunc: {
      type: Function,
      default: null
    },
    customFooter: {
      type: Boolean,
      default: false
    },
    disableForm: {
      type: Boolean,
      default: false
    },
    showCancel: {
      type: Boolean,
      default: true
    },
    confirmText: {
      type: String,
      default: null
    },
    handleCloseAfterCustomConfirm: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      form: {
        ...this.formFields
      },
      rules: {
        ...this.formRules
      },
      loading: false,
      options: regionData
    };
  },
  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit('update:visible', val);
      }
    }
  },
  created() {
    Object.keys(this.rules).forEach(k => {
      this.rules[k].forEach(r => {
        if (r.validator) {
          const method = r.validator.clone();
          r.validator = (rule, value, callback) => {
            method(this, rule, value, callback);
          };
        }
      });
    });
  },
  methods: {
    updateFormFields() {
      this.form = {
        ...this.formFields
      };
    },
    handleOpen() {
      if (this.object != null) {
        Object.keys(this.form).forEach(k => {
          const obj = this.object[k];
          if (typeof obj === 'object' && obj != null && obj.constructor && (obj.constructor.name === 'Object' || obj.constructor.name === 'Array')) {
            this.form[k] = deepClone(obj);
          } else if (obj != null) {
            this.form[k] = obj;
          }
        });
      } else {
        this.form = deepClone(this.formFields);
      }
      if (this.$refs.form) {
        this.$refs.form.clearValidate();
      }
      this.$emit('open', this.object);
    },
    handleClose() {
      this.$emit('close');
      this.dialogVisible = false;
      this.form = {
        ...this.formFields
      };
      this.loading = false;
      this.$refs.form.clearValidate();
    },
    async handleConfirm(closeAfterRequest) {
      const valid = await this.$refs.form.validate();
      if (valid) {
        let endpoint;
        if (this.endpoint != null) {
          if (typeof this.endpoint === 'string') {
            endpoint = this.endpoint;
          } else if (typeof this.endpoint === 'function') {
            endpoint = this.endpoint();
          }
        }
        if (endpoint == null) {
          this.$emit('confirm', {
            ...this.form
          });
          if (this.handleCloseAfterCustomConfirm) {
            this.handleClose();
          }
          return false;
        }
        this.loading = true;
        let requestData = {
          ...this.form
        };
        if (this.extraFormData) {
          requestData = {
            ...requestData,
            ...this.extraFormData
          };
        }
        if (this.formatRequestData) {
          requestData = this.formatRequestData(requestData);
        }
        let {
          id
        } = this.form;
        if (this.object != null) {
          id = this.object.id;
        }
        if (id == null) {
          let method;
          if (this.createItemFunc) {
            method = this.createItemFunc;
          } else {
            method = createItem;
          }
          const res = await method(endpoint, requestData);
          this.loading = false;
          this.$emit('confirm', res);
          if (closeAfterRequest !== false) {
            this.handleClose();
          }
          if (res.code === 0) {
            this.$message({
              message: this.$t('common.operation_success'),
              type: 'success',
              center: true
            });
            return true;
          }
        } else {
          let method;
          if (this.updateItemFunc) {
            method = this.updateItemFunc;
          } else {
            method = updateItem;
          }
          const res = await method(endpoint, id, requestData);
          this.loading = false;
          this.$emit('confirm', res);
          if (closeAfterRequest !== false) {
            this.handleClose();
          }
          if (res.code === 0) {
            this.$message({
              message: this.$t('common.operation_success'),
              type: 'success',
              center: true
            });
            return true;
          }
        }
      }
      return false;
    },
    disableItem(item, form) {
      if (this.disableForm) {
        return true;
      }
      if (this.loading) {
        return true;
      }
      if (item.disabled != null) {
        if (typeof item.disabled === 'function') {
          return item.disabled(form);
        }
        return item.disabled === true;
      }
      return false;
    }
  }
};