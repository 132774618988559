import "core-js/modules/es.array.push.js";
import { mapGetters } from 'vuex';
import Breadcrumb from '@/components/Breadcrumb/index.vue';
import { setup } from '@/i18n/index';
import { TIMEZONES } from '@/utils/timezone';
import EditDialog from '@/views/templates/EditDialog.vue';
import { update } from '@/api/common';
import { Notification } from 'element-ui';
import Message from '@/views/message/index.vue';
export default {
  components: {
    Breadcrumb,
    EditDialog,
    Message
  },
  data() {
    return {
      timezones: TIMEZONES,
      changePwdVisible: false,
      changePwdForm: {}
    };
  },
  computed: {
    ...mapGetters(['sidebar', 'name', 'en_name', 'timezone', 'unreadCount', 'avatar', 'role'])
  },
  methods: {
    toggleSideBar() {
      this.$store.dispatch('app/toggleSideBar');
    },
    async logout() {
      await this.$store.dispatch('user/logout');
      Notification.closeAll();
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    changeLanguage(lang) {
      this.$i18n.locale = lang;
      setup(lang);
    },
    changeTimezone(idx) {
      this.$store.dispatch('settings/changeSetting', {
        key: 'timezone',
        value: idx
      });
      window.location.reload();
    },
    checkMessage() {
      if (!this.$route.path.startsWith('/message')) {
        this.$router.push('message');
      }
    },
    changePassword() {
      this.changePwdForm = {};
      this.changePwdVisible = true;
    },
    async doChangePwd(form) {
      const res = await update('/auth/reset_password', form);
      if (res.code === 0) {
        this.$message({
          message: this.$t('common.operation_success'),
          type: 'success'
        });
      }
    },
    navProfile() {
      if (this.$route.path !== '/profile') {
        this.$router.push('/profile');
      }
    }
  }
};