import "core-js/modules/esnext.iterator.constructor.js";
import "core-js/modules/esnext.iterator.some.js";
import { Message } from 'element-ui';
import NProgress from 'nprogress'; // progress bar
import { getToken } from '@/utils/auth'; // get token from cookie
import getPageTitle from '@/utils/get-page-title';
import router from './router';
import store from './store';
import 'nprogress/nprogress.css'; // progress bar style

NProgress.configure({
  showSpinner: false
}); // NProgress Configuration

const whiteList = ['/login']; // no redirect whitelist

router.beforeEach(async (to, from, next) => {
  NProgress.start();
  document.title = getPageTitle(); // getPageTitle(to.meta.title);

  // determine whether the user has logged in
  const userToken = getToken();
  if (userToken) {
    if (to.path === '/login') {
      next({
        path: '/'
      });
      Message.warning('已登录');
    } else {
      const hasPermissions = store.getters.permissions && store.getters.permissions.length > 0;
      if (hasPermissions) {
        next();
      } else {
        try {
          // get user info
          const {
            permissions
          } = await store.dispatch('user/getInfo');
          if (permissions && permissions.length > 0) {
            // based on permission
            // generate accessible routes map based on permissions
            const accessRoutes = await store.dispatch('permission/generateRoutes', permissions);
            const showDashboard = store.getters.permissions.some(e => e.code === 'VIEW_OVERVIEW');
            if (!showDashboard) {
              accessRoutes[0].redirect = '/influencer';
            }
            // dynamically add accessible routes
            router.addRoutes(accessRoutes);
            // hack method to ensure that addRoutes is complete
            // set the replace: true, so the navigation will not leave a history record
            next({
              ...to,
              replace: true
            });
          } else {
            Message.warning('此帐号没有权限进入管理后台');
            // remove token and go to login page to re-login
            await store.dispatch('user/resetToken');
            next('/login');
            NProgress.done();
          }
        } catch (error) {
          Message.error(error && error.error && error.error.error_message || 'Has Error');
          // remove token and go to login page to re-login
          await store.dispatch('user/resetToken');
          next('/login');
          NProgress.done();
        }
      }
    }
  } else {
    if (whiteList.indexOf(to.path) !== -1) {
      next();
    } else {
      next('/login');
    }
    NProgress.done();
  }
});
router.afterEach(() => {
  // finish progress bar
  NProgress.done();
});