import request from '@/utils/http';
export function getList(endpoint, params) {
  return request({
    url: endpoint,
    method: 'get',
    params
  });
}
export function getListAll(endpoint, params) {
  return request({
    url: `${endpoint}all`,
    method: 'get',
    params
  });
}
export function search(endpoint, params) {
  return request({
    url: `${endpoint}search`,
    method: 'get',
    params
  });
}
export function createItem(endpoint, data) {
  return request({
    url: endpoint,
    method: 'post',
    data
  });
}
export function getItem(endpoint, id) {
  return request({
    url: `${endpoint}${id}`,
    method: 'get'
  });
}
export function get(endpoint, params) {
  return request({
    url: `${endpoint}`,
    method: 'get',
    params
  });
}
export function update(url, data) {
  return request({
    url,
    method: 'put',
    data
  });
}
export function updateItem(endpoint, id, data) {
  return request({
    url: `${endpoint}${id}`,
    method: 'put',
    data
  });
}
export function deleteItem(endpoint, id) {
  return request({
    url: `${endpoint}${id}`,
    method: 'delete'
  });
}
export function deleteUrl(url) {
  return request({
    url,
    method: 'delete'
  });
}
export function publishItem(endpoint, id, data) {
  return request({
    url: `${endpoint}${id}/publish`,
    method: 'put',
    data
  });
}