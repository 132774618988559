/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { getToken, setToken, removeToken } from '@/utils/auth';
import { resetRouter } from '@/router';
// eslint-disable-next-line import/no-cycle
import { authLogin, authInfo } from '@/api/auth';
import Cookies from 'js-cookie';

// get user info remote
const state = {
  token: getToken(),
  name: '',
  en_name: '',
  username: '',
  permissions: [],
  role: {},
  avatar: null,
  info: null
};
const mutations = {
  SET_TOKEN: (State, token) => {
    state.token = token;
  },
  SET_NAME: (State, name) => {
    state.name = name;
  },
  SET_ENNAME: (State, en_name) => {
    state.en_name = en_name;
  },
  SET_USERNAME: (State, username) => {
    state.username = username;
  },
  SET_PERMISSIONS: (State, permissions) => {
    state.permissions = permissions;
  },
  SET_ROLE: (State, role) => {
    state.role = role;
  },
  SET_AVATAR: (State, avatar) => {
    state.avatar = avatar;
  },
  SET_INFO: (State, info) => {
    state.info = info;
  },
  RESET_STATE: State => {
    state.permissions = [];
    state.name = '';
    state.username = '';
    state.role = {};
    state.avatar = null;
    state.info = null;
  }
};
const actions = {
  getInfo({
    commit
  }) {
    return new Promise((resolve, reject) => {
      authInfo().then(response => {
        const {
          data
        } = response;
        commit('SET_NAME', `${data.name || data.username} (${window.vue.$t(`account.${data.role.name}`)})`);
        commit('SET_ENNAME', data.en_name || data.username);
        commit('SET_USERNAME', data.username);
        commit('SET_AVATAR', data.avatar && data.avatar.view_url);
        commit('SET_INFO', data);
        const {
          username,
          role
        } = data;
        const {
          permissions
        } = role;
        commit('SET_ROLE', role);
        commit('SET_PERMISSIONS', permissions);
        resolve({
          username,
          permissions
        });
      }).catch(error => {
        reject(error);
      });
    });
  },
  login({
    commit
  }, userInfo) {
    return new Promise((resolve, reject) => {
      authLogin(userInfo).then(response => {
        const {
          data
        } = response;
        commit('SET_TOKEN', data.access_token);
        setToken(data.access_token);
        resolve(data);
      }).catch(error => {
        reject(error);
      });
    });
  },
  // user logout
  logout({
    commit
  }) {
    return new Promise(resolve => {
      commit('SET_TOKEN', '');
      commit('SET_PERMISSIONS', []);
      removeToken();
      resetRouter();
      resolve();
    });
  },
  // remove token
  resetToken({
    commit
  }) {
    return new Promise(resolve => {
      removeToken();
      commit('RESET_STATE');
      resolve();
    });
  }
};
export default {
  namespaced: true,
  state,
  mutations,
  actions
};